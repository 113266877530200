import React, { useEffect } from 'react';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { Debt, DebtRecommendation, DebtSummary, HardOfferData } from 'handlers/applicationData';
import { FlowComponentType } from 'routes/types';
import { ReactComponent as LockOpenIcon } from 'images/lock-open.svg';
import { ReactComponent as GiftIcon } from 'images/gift.svg';
import { ButtonType } from 'components/Button/Button';
import { YourSavingsResult } from 'enums/FlowNextResults';
import { RootState } from 'handlers';
import { PartnerName, getPartnerName } from 'enums/PartnerName';
import { Environments, getEnvironment } from 'utils/getEnvironment';
import { ConversionType, trackConversion } from 'utils/analytics';
import LoanSummary, { MessageType } from 'components/HardOffer/components/PayOffPlan/components/LoanSummary';

import styles from './YourSavings.module.scss';

const YourSavings = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const { partnerName } = useSelector((state: RootState) => state.preQualificationData);
  const { hardOffer, debtSummary, maxLoanAmount } = useSelector(getApplicationData).application ?? {};
  const { applied: cardApplied } = useSelector(getCardData);
  const { offerSummary } = (hardOffer ?? {}) as HardOfferData;
  const { loanAmount, monthsSaved, moneySaved, monthlyPaymentSaved } = offerSummary ?? {};

  const totalDebt = Object.values((debtSummary ?? {}) as DebtSummary)
    .filter((debt: Debt) => debt.recommendation === DebtRecommendation.PayOff)
    .reduce((total, current) => total + current.totalBalance!, 0);

  /**
     * TODO: Replace subtitle
  
    const displaySubtitle = (
      <p>
        See how you can use a <b>{formatMonetaryAmount(Math.min(totalDebt, maxLoanAmount))}</b> healthcare employee loan
        to pay off your high interest debt faster.
      </p>
    */

  const debtExceedsAvailableAmount = totalDebt > (maxLoanAmount ?? 0);

  const displaySubtitle = `Plannery can help you pay your ${
    debtExceedsAvailableAmount
      ? `up to ${formatMonetaryAmount(maxLoanAmount)} of your`
      : `your ${formatMonetaryAmount(totalDebt)} of`
  } high interest debt${(monthsSaved ?? 0) > 0 ? ' faster' : ''}.`;

  let title = 'We’ve Unlocked Savings for You!';

  if (cardApplied) {
    title = `Your Savings: ${formatMonetaryAmount(moneySaved)}`;
  }

  useEffect(() => {
    trackConversion(ConversionType.DebtConsolidationOfferAvailable);
  }, []);

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer className={styles.formContainer} icon={<LockOpenIcon />} title={title} subtitle={displaySubtitle}>
        <LoanSummary
          loanAmount={loanAmount}
          moneySaved={moneySaved}
          monthsSaved={monthsSaved}
          monthlyPaymentSaved={monthlyPaymentSaved}
          messageType={MessageType.Import}
        />

        {partnerName === PartnerName.Hillcrest && getEnvironment() === Environments.Staging && (
          <div className={styles.loyaltyContainer}>
            <div className={styles.message}>
              Your offer includes a loyalty discount for working at {getPartnerName(partnerName)} for over 5 years.
            </div>
            <GiftIcon className={styles.gift} />
          </div>
        )}

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              handleNext(YourSavingsResult.Continue);
            }}
          >
            See how it works
          </Button>
          {cardApplied && (
            <Button
              type={ButtonType.Inverted}
              className={styles.buttonMarginTop}
              onClick={() => {
                handleNext(YourSavingsResult.BackToYourFinances);
              }}
            >
              Not Now
            </Button>
          )}
        </div>
      </FormContainer>
    </div>
  );
};

export default YourSavings;
