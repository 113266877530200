import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';
import Input from 'components/Input';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { EMAIL_PATTERN, PHONE_NUMBER_LENGTH } from 'components/LoanForm/YourContact/YourContact';
import { StepsResult } from 'enums/FlowNextResults';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { updateStudentLoanApplication } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { ANNUAL_INCOME_MAX_LENGTH } from 'components/Verification/Steps/EmploymentDetails/EmploymentDetails';
import NumberInput from 'components/NumberInput';
import AddressFields from 'components/AddressFields';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Address, isCompleteAddress } from 'components/AddressFields/AddressFields';

import styles from './EmploymentDetails.module.scss';

enum Field {
  AdjustedGrossIncome = 'Adjusted gross income',
  EmployerName = 'Employer name',
  HrContactName = 'HR contact name',
  HrContactEmail = 'HR contact email address',
  EmployerPhone = 'Employer phone',
}

const EmploymentDetails = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const {
    applicationId,
    applicationData: {
      adjustedGrossIncome,
      employerName,
      hrContactName,
      hrContactEmail,
      employerPhone,
      employerAddress,
      employerCity,
      employerState,
      employerZip,
    },
  } = useSelector(getStudentLoanApplication);

  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState<Address>({
    line1: employerAddress,
    city: employerCity,
    state: employerState,
    zip: employerZip,
  });
  const [isAddressValid, setIsAddressValid] = useState(isCompleteAddress(address));

  const {
    formState: { errors, isValid },
    trigger,
    register,
    watch,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [Field.AdjustedGrossIncome]: adjustedGrossIncome,
      [Field.EmployerName]: employerName,
      [Field.HrContactName]: hrContactName,
      [Field.HrContactEmail]: hrContactEmail,
      [Field.EmployerPhone]: employerPhone,
    },
  });
  const watcher = watch();

  useEffect(() => {
    register(Field.AdjustedGrossIncome, {
      required: getMessageForRequiredFields(Field.AdjustedGrossIncome),
    });
    register(Field.EmployerName, {
      required: getMessageForRequiredFields(Field.EmployerName),
    });
    register(Field.HrContactName, {
      required: getMessageForRequiredFields(Field.HrContactName),
    });
    register(Field.HrContactEmail, {
      required: getMessageForRequiredFields(Field.HrContactEmail),
      pattern: {
        message: getMessageForInvalidFields(Field.HrContactEmail),
        value: EMAIL_PATTERN,
      },
    });
    register(Field.EmployerPhone, {
      required: getMessageForRequiredFields(Field.EmployerPhone),
      validate: (value: string) =>
        isPossiblePhoneNumber(value, 'US') ? true : getMessageForInvalidFields(Field.EmployerPhone),
    });
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as Field, event.target.value.trim());
    trigger(event.target.name as Field);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as Field, event.target.value);
    trigger(event.target.name as Field);
  };

  const onNext = async () => {
    setIsLoading(true);

    await dispatchWithUnwrap(
      updateStudentLoanApplication({
        applicationId: applicationId!,
        applicationData: {
          adjustedGrossIncome: watcher[Field.AdjustedGrossIncome],
          employerName: watcher[Field.EmployerName],
          hrContactName: watcher[Field.HrContactName],
          hrContactEmail: watcher[Field.HrContactEmail],
          employerPhone: watcher[Field.EmployerPhone],
          employerAddress: address.line1,
          employerCity: address.city,
          employerState: address.state,
          employerZip: address.zip,
        },
      }),
    );

    analytics.track('Student Loan Employment Details Submitted');
    handleNext(StepsResult.Completed);
    setIsLoading(false);
  };

  return (
    <FormContainer
      title="Employment Details"
      subtitle={`This is needed to verify your employment${employerName ? ` at ${employerName}.` : '.'}`}
    >
      <NumberInput
        label={Field.AdjustedGrossIncome}
        prefix="$"
        placeholder="$0"
        errorMessage={errors[Field.AdjustedGrossIncome]?.message}
        thousandSeparator
        name={Field.AdjustedGrossIncome}
        onChange={(event) => {
          setValue(Field.AdjustedGrossIncome, Number(event.target.value.replace(/[^0-9.-]+/g, '')));
          trigger(Field.AdjustedGrossIncome);
        }}
        value={watcher[Field.AdjustedGrossIncome] ? `${watcher[Field.AdjustedGrossIncome]}` : undefined}
        maxLength={ANNUAL_INCOME_MAX_LENGTH}
      />

      {!employerName && (
        <Input
          label={Field.EmployerName}
          placeholder={Field.EmployerName}
          errorMessage={errors[Field.EmployerName]?.message}
          name={Field.EmployerName}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[Field.EmployerName]}
        />
      )}
      <Input
        label={Field.HrContactName}
        placeholder={Field.HrContactName}
        errorMessage={errors[Field.HrContactName]?.message}
        name={Field.HrContactName}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.HrContactName]}
      />
      <Input
        label={Field.HrContactEmail}
        placeholder="hr@example.com"
        errorMessage={errors[Field.HrContactEmail]?.message}
        name={Field.HrContactEmail}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.HrContactEmail]}
      />
      <AddressFields
        prefix="Employer"
        value={address}
        onChange={(value, isAddressValidValue) => {
          setAddress(value);
          setIsAddressValid(isAddressValidValue);
        }}
        hideLine2
      />
      <PhoneNumberInput
        label={Field.EmployerPhone}
        placeholder="000-000-0000"
        errorMessage={errors[Field.EmployerPhone]?.message}
        name={Field.EmployerPhone}
        onChange={(value) => {
          setValue(Field.EmployerPhone, value);
          trigger(Field.EmployerPhone);
        }}
        value={watcher[Field.EmployerPhone]}
        maxLength={PHONE_NUMBER_LENGTH}
        country="US"
      />
      <Button disabled={!isValid || !isAddressValid} className={styles.button} onClick={onNext} isLoading={isLoading}>
        Save and Continue
      </Button>
    </FormContainer>
  );
};

export default EmploymentDetails;
