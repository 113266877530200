import React from 'react';
import { useSelector } from 'react-redux';

import { FlowComponentType } from 'routes/types';

import Button from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';
import ImportBalanceTable from 'components/Common/ImportBalanceTable';
import { DebtSummary, LoanType } from 'handlers/applicationData';
import { getCardData } from 'selectors/getCardData';
import { formatNumber } from 'utils/formatNumber';
import { getApplicationData } from 'selectors/getApplicationData';

import { ReactComponent as BankIcon } from 'images/bank.svg';
import { ReactComponent as PlanneryIcon } from 'images/plannery.svg';
import { ReactComponent as ArrowNext } from 'images/arrow-next.svg';

import styles from './OfferAvailable.module.scss';

const OfferAvailable = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const debtSummary = (useSelector(getApplicationData)?.application?.debtSummary ??
    useSelector(getCardData)?.debtSummary ??
    {}) as Partial<DebtSummary>;

  const subtitle = getSubtitle(debtSummary);

  // TODO: Make title You Qualify for a Healthcare Employee Loan!
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Bring Your Balance to Plannery" subtitle={subtitle}>
        <ImportBalanceTable
          importLabel={<ArrowNext />}
          leftColumn={{
            icon: <BankIcon />,
            label: 'Your Balance',
          }}
          rightColumn={{
            icon: <PlanneryIcon />,
            label: 'Plannery',
          }}
        />

        <p className={styles.subtext}>
          Bring this balance to Plannery to access below market interest rates exclusively for healthcare professionals.
        </p>

        <Button onClick={handleNext}>Next</Button>
      </FormContainer>
    </>
  );
};

const getSubtitle = (debtSummary: Partial<DebtSummary>) => {
  const personalLoanBalance = debtSummary[LoanType.PersonalLoan]?.totalConsolidatableBalance ?? 0;
  const creditCardBalance = debtSummary[LoanType.CreditCard]?.totalConsolidatableBalance ?? 0;

  const balanceLabels: string[] = [];

  if (personalLoanBalance > 0) {
    balanceLabels.push(`$${formatNumber(personalLoanBalance)} in personal loans`);
  }
  if (creditCardBalance > 0) {
    balanceLabels.push(`$${formatNumber(creditCardBalance)} in credit cards`);
  }

  // TODO: `Use this loan to pay off your ${balanceLabels.join(' and ')}.`;
  return `You have ${balanceLabels.join(' and ')}.`;
};

export default OfferAvailable;
